/***

  General Configurations
  ---------------------------------

***/

$secondary-tint: -20;
$mobile-breakpoint: '1025px';

/***

  Spreadsheet Layout Configurations
  ---------------------------------
  - ROW_PADDING - the padding for each row (in px)
  - SCROLLBAR_SIZE - the width of the horizontal and vertical scrollbars (in px)
  - ROW_HEIGHT - the height of each row (in px)
  - HEADER_HEIGHT - the height of the header component containing column names (in px)
  - TOOLBAR_HEIGHT - the height of the toolbar component containing spreadsheet actions (in px)
  - STATS_HEIGHT - the height of the stats bar at the bottom of the spreadsheet (in px)
  - ROW_NUMBER_COLUMN_WIDTH - the width of the first column containing row numbers (in px)
  - COLUMN_MIN_WIDTH - Minimum width of a column (in px)
  - FROZEN_SHADOW_WIDTH - The width of the shadow on the right hand side of the final frozen column (in px)
  - DEFAULT_MENU_WIDTH - Minimum width of a context menu (in px)

***/

$row-padding: 16;
$scrollbar-size: 6;
$small-row-height: 33;
$medium-row-height: 66;
$tall-row-height: 99;
$extra-tall-row-height: 132;
$header-height: 66;
$toolbar-height: 44;
$stats-height: 44;
$row-number-column-width: 60;
$column-min-width: 100;
$frozen-shadow-width: 3;
$default-menu-width: 100;
$option-min-height: 30;
$option-offset: 15;

/***

  Colour Palette
  --------------------------
  
***/

$pink: #e92aba;
$red: #f72c60;
$light-red: #ff99b1;
$really-light-red: #fff8f8;
$green: #59cd90;
$dark-green: #10af22;
$light-blue: #ebf7ff;
$really-light-blue: #f0f7ff;
$blue: #2e7ff9;
$text-secondary: #707070;
$text-primary: #333333;
$grey: #d9dbde;
$light-grey: #f5f5f5;
$really-light-grey: #fafafa;
$really-really-light-grey: #f2f2f2;
$darker-grey: #555555;
$orange: #ff4e0d;
$light-orange: #fef3ee;
$light-green: #e8fff3;
$light-blue: #d0f0fd;
$light-purple: #ece2fe;
$light-teal: #cef4f3;
$light-pink: #f8e1f2;
$light-yellow: #ffeab6;
$really-light-yellow: #fff3d4;
$medium-grey: #b9b9b9;
$ai-background: #f6ffdb;
$alert-color: #f1c744;

/***

  Exported constants
  ------------------
  These properties can be use in TSX files
  Exported numeric values will need to be cast to Numeric() when using them in a TSX file.
  
***/

:export {
  secondaryTint: $secondary-tint;
  mobileBreakpoint: $mobile-breakpoint;

  toolbarHeight: $toolbar-height;
  rowPadding: $row-padding;
  scrollbarSize: $scrollbar-size;
  smallRowHeight: $small-row-height;
  mediumRowHeight: $medium-row-height;
  tallRowHeight: $tall-row-height;
  extraTallRowHeight: $extra-tall-row-height;
  headerHeight: $header-height;
  statsHeight: $stats-height;
  rowNumberColumnWidth: $row-number-column-width;
  columnMinWidth: $column-min-width;
  frozenShadowWidth: $frozen-shadow-width;
  defaultMenuWidth: $default-menu-width;
  optionMinHeight: $option-min-height;
  optionOffset: $option-offset;

  pink: $pink;
  red: $red;
  lightRed: $light-red;
  reallyLightRed: $really-light-red;
  green: $green;
  darkGreen: $dark-green;
  lightBlue: $light-blue;
  blue: $blue;
  textSecondary: $text-secondary;
  textPrimary: $text-primary;
  grey: $grey;
  lightGrey: $light-grey;
  reallyLightGrey: $really-light-grey;
  reallyReallyLightGrey: $really-really-light-grey;
  darkerGrey: $darker-grey;
  orange: $orange;
  lightOrange: $light-orange;
  lightGreen: $light-green;
  lightBlue: $light-blue;
  reallyLightBlue: $really-light-blue;
  lightPurple: $light-purple;
  lightTeal: $light-teal;
  lightYellow: $light-yellow;
  reallyLightYellow: $really-light-yellow;
  lightPink: $light-pink;
  aiBackground: $ai-background;
  alertColor: $alert-color;
}
